import React from 'react';
import ReactDOM from 'react-dom';
import Linktree, { CHANNEL_TYPES } from 'linkees';

import './css/normalize.css';

const items = [
  {
    title: 'Site Principal',
    subtitle: 'Refúgio SC',
    type: CHANNEL_TYPES.WEBSITE,
    link: 'https://refugiorp.com.br',
  },
  {
    title: 'FiveM',
    image: 'https://cdn.refugiorp.com.br/tree/fivem.png',
    subtitle: 'Você pode fazer a conexão direta em nosso servidor.',
    type: CHANNEL_TYPES.OTHER,
    link: 'https://cfx.re/join/g5vxkx',
  },
  {
    title: 'Discord',
    image: 'https://cdn.refugiorp.com.br/tree/discord.png',
    subtitle: 'Começe sua história na Refúgio agora.',
    type: CHANNEL_TYPES.WEBSITE,
    link: 'https://discord.com/invite/mRVXYfF',
  },
  {
    title: 'Instagram',
    subtitle: 'Siga nos em nosso instagram 🙂',
    type: CHANNEL_TYPES.INSTAGRAM,
    link: 'https://instagram.com/refugioscrp',
  },

  {
    title: 'TikTok',
    image: 'https://cdn.refugiorp.com.br/tree/tiktok.png',
    subtitle: 'Acompanhe nossos videos',
    type: CHANNEL_TYPES.OTHER,
    link: 'https://www.tiktok.com/@zsraphael',
  },
  {
    title: 'Site de Doações',
    subtitle: 'Ajude nos a manter nosso servidor online.',
    type: CHANNEL_TYPES.WEBSITE,
    link: 'https://donate.refugiorp.com.br',
  },
];

ReactDOM.render(
  <React.StrictMode>
    <Linktree
      name="Refúgio Roleplay"
      cardItems={items}
      headerAvatar="https://cdn.discordapp.com/attachments/1004140276392145057/1088591305556361326/logoteste2.png"
    />
  </React.StrictMode>,
  document.getElementById('root')
);
